import React from 'react'
import { useTranslation } from 'react-i18next'
import TEST_IDS from '../../constants/query-selectors'

const GenericError = () => {
    const { t } = useTranslation()

    return (
        <>
            <h1 data-testid={TEST_IDS.error.header}> {t('Something went wrong')} </h1>
            <p data-testid={TEST_IDS.error.message} className='h5'>
                {' '}
                {t('Please try again later')}{' '}
            </p>
        </>
    )
}

export default GenericError
