import React from 'react'
import Box from 'tcweb-material-components/core/box'
import { makeStyles } from '@material-ui/styles'

import FooterMicrosoft from './microsoft'
import FooterSite from './site'

const useStyles = makeStyles(({ palette }) => ({
    root: {
        borderTop: `1px solid ${palette.border.primary}`,
    }
}))

export default (props) => {
    const _classes = useStyles()

    return (
        <Box classes={{ root: _classes.root }} color='primary' component='footer' gutters='navigation'>
            <FooterSite isFranceHomepage={props.path === '/fr-fr'} />
            <FooterMicrosoft />
        </Box>
    )
}
