import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import { makeStyles, useTheme } from '@material-ui/core/styles'
import classNames from 'classnames'

import PlaceholderImg from '../common/placeholderImg'

const useStyles = makeStyles(() => ({
    img: { width: '100%' }
}))

const MenuImage = ({ className, fluidImg }) => {
    const imgStyles = useStyles()
    const theme = useTheme()

    if (!!fluidImg) {
        return (
            <GatsbyImage
                image={fluidImg}
                className={classNames(className, imgStyles.img)}
                backgroundColor={theme.palette.img.backgroundLoading}
                role='presentation' />
        );
    } else {
        return <PlaceholderImg className={classNames('menuImage', imgStyles.img, className)} role='presentation' />
    }
}

export default MenuImage
