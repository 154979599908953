import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import { useStaticQuery, graphql } from 'gatsby'
import Facebook from 'tcweb-material-components/core/social/facebook'
import Instagram from 'tcweb-material-components/core/social/instagram'
import Discord from 'tcweb-material-components/core/social/discord'
import Twitch from 'tcweb-material-components/core/social/twitch'
import Twitter from 'tcweb-material-components/core/social/twitter'
import YouTube from 'tcweb-material-components/core/social/youtube'
import find from 'tcweb-material-components/core/poly/find'
import map from 'tcweb-material-components/core/poly/map'
import get from 'tcweb-material-components/core/poly/get'
import Image from 'tcweb-material-components/core/image'
import TCMenuItem from 'tcweb-material-components/core/siteMenu/menuItem'

import useLogo from '../../hooks/useLogo'
import LocaleContext from '../context/localeContext'
import ImgWithSVGSupport from '../common/imgWithSVGSupport'
import Link from '../common/link'
import TEST_IDS from '../../constants/query-selectors'

const TABLET_SPACING = '30px'
const MOBILE_SPACING = '20px'
const ITEM_GAP = '40px'

const useStyles = makeStyles((theme) => ({
    highContrast: {
        ...theme.accessibility.highContrast.blackOnWhite
    },
    section: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: MOBILE_SPACING,
        paddingBottom: MOBILE_SPACING,
        borderBottom: `1px solid ${theme.palette.border.primary}`,
        '& a': {
            width: 'auto',
            marginRight: ITEM_GAP,
            '&:last-of-type': {
                marginRight: 0
            }
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: TABLET_SPACING,
            paddingBottom: TABLET_SPACING
        }
    },
    siteLink: {
        [theme.breakpoints.up('md')]: {
            fontSize: '1.1em'
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '1.33em'
        }
    },
    franchiseLinks: {
        display: 'grid',
        gridRowGap: MOBILE_SPACING,
        gridTemplateColumns: '1fr 1fr',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexWrap: 'wrap'
        }
    },
    companyLinks: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            borderBottom: `1px solid ${theme.palette.border.primary}`
        },
        '& > nav': {
            [theme.breakpoints.up('md')]: {
                paddingTop: '10px',
                paddingBottom: '10px',
                borderBottom: 'none'
            }
        }
    },
    companyLogoLinks: {
        display: 'grid',
        justifyContent: 'start',
        gridRowGap: MOBILE_SPACING,
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            marginRight: ITEM_GAP
        }
    },
    ratingImage: {
        [theme.breakpoints.only('sm')]: {
            marginBottom: 0,
            gridColumnStart: 1,
            gridColumnEnd: 3
        }
    },
    tcImage: { maxWidth: '100%' },
    xboxImage: { maxWidth: '100%' },
    socialMediaLinks: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: MOBILE_SPACING,
        paddingBottom: MOBILE_SPACING,
        borderBottom: `1px solid ${theme.palette.border.primary}`,
        '& a': {
            width: 'auto',
            textAlign: 'center'
        },
        [theme.breakpoints.only('sm')]: {
            paddingTop: TABLET_SPACING,
            paddingBottom: TABLET_SPACING
        },
        [theme.breakpoints.up('sm')]: {
            '& a': {
                width: 'auto',
                marginRight: ITEM_GAP,
                '&:last-of-type': {
                    marginRight: 0
                }
            }
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-start',
            borderBottom: 'none'
        }
    }
}))

export default ({ isFranceHomepage }) => {
    const localeContext = useContext(LocaleContext)
    const { t } = useTranslation()
    const _classes = useStyles()
    const coalitionLogo = useLogo('theCoalition')
    const xboxGameStudiosLogo = useLogo('xboxGameStudios')

    const data = useStaticQuery(graphql`
        {
            FranchiseMenuFooter: allWpSiteMenu(filter: { slug: { eq: "franchise-menu-footer" } }) {
                nodes {
                    ...franchiseFooterGraphqlFragment
                }
            }
            rating: allWpSiteRating {
                nodes {
                    ...ratingsGraphqlFragment
                }
            }
        }
    `)

    const currentLocaleRating = find((n) => n.ratingACF.locales.includes(localeContext.locale), get(data, 'rating.nodes'))
    const franchiseMenuFooterItems = get(data, 'FranchiseMenuFooter.nodes.0.menuItems.nodes')

    return (
        <>
            <div className={`${_classes.section} ${_classes.franchiseLinks}`}>
                <TCMenuItem className={_classes.siteLink} href='/' aria-label={t('Go to Gears of War home')} component={Link} color='primary'>
                    {t('Gears of War')}
                </TCMenuItem>

                {map(
                    (item) => (
                        <TCMenuItem
                            data-testid={TEST_IDS.footerSite.franchiseLink}
                            key={item.label}
                            className={_classes.siteLink}
                            href={item.url}
                            aria-label={t(item.label)}
                            target={item.target}
                            component={Link}
                            color='primary'
                        >
                            {t(item.label)}
                        </TCMenuItem>
                    ),
                    franchiseMenuFooterItems
                )}
            </div>
            {isFranceHomepage && (
                <div data-testid={TEST_IDS.footerSite.franceOnlyMessage} className={_classes.section}>
                    <span>Accessibilité: partiellement conforme</span>
                </div>
            )}
            <div data-testid={TEST_IDS.footerSite.companyLinks} className={_classes.companyLinks}>
                <nav aria-label={t('Company Logo Links')} className={`${_classes.section} ${_classes.companyLogoLinks}`}>
                    {!!get(currentLocaleRating, 'featuredImage.node.localFile') && (
                        <TCMenuItem
                            data-testid={TEST_IDS.footerSite.companyLink}
                            className={_classes.ratingImage}
                            href={currentLocaleRating.ratingACF.url}
                            title={currentLocaleRating.title}
                            aria-label={t('slug:title-rating-site', { title: currentLocaleRating.title })}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <ImgWithSVGSupport
                                childImageSharp={currentLocaleRating.featuredImage.node.localFile.childImageSharp}
                                extension={currentLocaleRating.featuredImage.node.localFile.ext}
                                publicURL={currentLocaleRating.featuredImage.node.sourceUrl}
                                alt=''
                                role='presentation'
                                height={70}
                                style={{ height: 70 }}
                            />
                        </TCMenuItem>
                    )}
                    <Link
                        style={{ transform: 'translateY(-5px)' }}
                        href='https://thecoalitionstudio.com'
                        title={t('Go to The Coalition Studio')}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <Image height={34} className={_classes.tcImage} src={coalitionLogo} alt='' role='presentation' />
                    </Link>
                    <Link
                        href='https://www.xbox.com/xbox-game-studios'
                        title={t('Go to Xbox Game Studios')}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <Image height={25} className={_classes.xboxImage} src={xboxGameStudiosLogo} alt='' role='presentation' />
                    </Link>
                </nav>
                <nav aria-label={t('Social media links')} className={_classes.socialMediaLinks}>
                    <Twitter
                        href='https://twitter.com/gearsofwar'
                        data-testid={TEST_IDS.footerSite.twitterLink}
                        target='_blank'
                        rel='noopener noreferrer'
                        FontAwesomeIconProps={{ size: '2x' }}
                        title='X'
                        aria-label='X'
                    />
                    <Facebook
                        href='https://www.facebook.com/gearsofwar'
                        data-testid={TEST_IDS.footerSite.facebookLink}
                        target='_blank'
                        rel='noopener noreferrer'
                        FontAwesomeIconProps={{ size: '2x' }}
                    />
                    <Twitch
                        href='https://www.twitch.tv/gearsofwar/'
                        data-testid={TEST_IDS.footerSite.twitchLink}
                        target='_blank'
                        rel='noopener noreferrer'
                        FontAwesomeIconProps={{ size: '2x' }}
                    />
                    <YouTube
                        href='https://www.youtube.com/channel/UC3E8iPQGBdAnOQR_cmkEbsw'
                        data-testid={TEST_IDS.footerSite.youtubeLink}
                        target='_blank'
                        rel='noopener noreferrer'
                        FontAwesomeIconProps={{ size: '2x' }}
                    />
                    <Instagram
                        href='https://www.instagram.com/gearsofwar'
                        data-testid={TEST_IDS.footerSite.instagramLink}
                        target='_blank'
                        rel='noopener noreferrer'
                        FontAwesomeIconProps={{ size: '2x' }}
                    />
                    <Discord
                        href='https://discord.gg/gearsofwar'
                        target='_blank'
                        rel='noopener noreferrer'
                        FontAwesomeIconProps={{ size: '2x' }}
                    />
                </nav>
            </div>
        </>
    )
}
