import React, { useMemo, useEffect, useState, useContext } from 'react'
import moment from 'moment'
import { useStaticQuery, graphql } from 'gatsby'
import range from 'lodash/range'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import NativeSelect from '@material-ui/core/NativeSelect'
import find from 'tcweb-material-components/core/poly/find'
import Cookie from 'js-cookie'
import Button from 'tcweb-material-components/core/button'
import Dialog from 'tcweb-material-components/core/dialog'
import get from 'tcweb-material-components/core/poly/get'
import ThemeProvider from 'tcweb-material-components/core/themeProvider'
import { getFirstFocusableChild, isSamsungInternet } from 'tcweb-material-components/core/utilities/helpers'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import TEST_IDS from '../../constants/query-selectors'
import LocaleContext from '../context/localeContext'
import webstarterLogoWhite from '../../assets/images/logos/webstarter-white.svg'
import gearsOfWarLogoWhite from '../../assets/images/logos/gearsOfWar-vertical-no-omen-white.svg'

const theme = {
    palette: {
        text: {
            primary: '#FFFFFF'
        }
    }
}

const useStyles = makeStyles(({ breakpoints, gutters, palette, accessibility: { highContrast } }) => ({
    container: {
        padding: gutters.page.xs,
        [breakpoints.up('md')]: { padding: gutters.page.md },
        [breakpoints.up('lg')]: { padding: gutters.page.lg }
    },
    paper: {
        padding: 0,
        overflowX: 'hidden',
        alignItems: 'center',
        boxShadow: '0 10px 20px 0px rgba(0, 0, 0, 0.50)',
        backgroundColor: '#000',
        backgroundSize: 'cover',
    },
    content: {
        overflow: 'visible',
        margin: 'auto',
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        textAlign: 'center',
        minHeight: '100%',
        background: 'linear-gradient(transparent 50%, rgba(0, 0, 0, 0.5) 60%)',
        padding: '0 !important',
        paddingTop: '100px',
        width: '100%',
        '@media (max-height: 450px)': {
            background: 'linear-gradient(transparent 5%, rgba(0, 0, 0, 0.5) 50%)'
        },
        [breakpoints.up('md')]: {
            background: 'radial-gradient(closest-side at center 70%, rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.5) 70%, transparent 80%)',
            width: '50%'
        }
    },
    logo: {
        marginTop: '5vh',
        maxWidth: '300px',
        paddingLeft: '1em',
        paddingRight: '1em',
        marginBottom: 'auto',
        [breakpoints.up('md')]: { maxWidth: '100%' }
    },
    contentText: {
        color: '#fff',
        lineHeight: '1',
        textTransform: 'uppercase',
        fontStretch: 'condensed',
        fontWeight: '700',
        marginBottom: '5vh',
        marginTop: '1em',
        [breakpoints.up('md')]: {
            margin: '3vh'
        }
    },
    contentTextBlocked: {
        lineHeight: '1',
        textTransform: 'uppercase',
        fontStretch: 'condensed',
        fontWeight: '700',
        marginBottom: '150px',
        [breakpoints.up('md')]: { marginBottom: '200px' }
    },
    form: {
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green'
        }
    },
    fieldContainer: {
        backgroundColor: palette.common.black,
        borderRadius: '6px',
        padding: '6px',
        display: 'grid',
        gridGap: '20px',
        gridTemplateColumns: '1fr',
        [breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(3, 1fr)'
        }
    },
    select: {
        color: palette.common.white,
        '& option': {
            color: palette.common.white
        },
        '&:focus': {
             outline: `2px solid ${palette.common.white}`,
         }
    },

    icon: {
        [highContrast.mediaQuery('active')]: {
            color: 'WindowText'
        }
    },
    underline: {
        '&:before': {
            borderBottom: '1px solid',
            borderBottomColor: palette.primary.contrastText
        }
    },
    submit: {
        width: '75%',
        margin: '30px',
        [breakpoints.up('md')]: {
            width: '50%',
            margin: '3vh'
        }
    }
}))

const currentYear = new Date().getFullYear()
function isNegative(number) {
    return !Object.is(Math.abs(number), +number); // https://stackoverflow.com/a/61201114
}

function isUnderaged(inputDate, ageLimit) {
    var today = new Date() // minus ageLimit
    today.setFullYear(today.getFullYear() - ageLimit);
    var birthDate = new Date(inputDate)

    let timeDiff = today.getTime() - birthDate.getTime();

    // Calculating the no. of days between
    let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24));

    return isNegative(daysDiff)
}

function unFormatDate(inputString) {
    const [year, month, day] = inputString.split('-')
    return [year, month, day]
}

function formatDate(year, month, day) {
    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
}

function daysInMonth(year, month) {
   return moment(`${year}-${month === 0 ? 1 : month}`, 'YYYY-MM').daysInMonth()
}

const AgeGate = () => {
    const { t } = useTranslation()
    const { locale } = useContext(LocaleContext)
    const data = useStaticQuery(graphql`
        {
            site {
                siteMetadata { title }
            }
            rating: allWpSiteRating {
                nodes {
                    ...ratingsGraphqlFragment
                }
            }
        }
    `)

    const currentLocaleRating = find((n) => n.ratingACF.locales.includes(locale), get(data, 'rating.nodes'))
    const ageGate = get(currentLocaleRating, 'ratingACF.ageGate')
    const minAge = ageGate || parseInt(ageGate)
    const cookieDateOfBirth = Cookie.get('dob')
    let cookieAge = getAge(cookieDateOfBirth)

    if (cookieDateOfBirth) {
        let [_year, _month, _day] = unFormatDate(cookieDateOfBirth)
        cookieAge = getAge(new Date(_year, _month, _day))
    }

    const showAgeGate = !cookieAge || cookieAge < minAge

    const [isFormDirty, setIsFormDirty] = useState(false)
    const [open, setOpen] = useState(showAgeGate)
    const [blocked, setBlocked] = useState(cookieAge < minAge)
    const [day, setDay] = useState(1)
    const [month, setMonth] = useState(0)
    const [year, setYear] = useState(currentYear)
    const numOfDaysInMonth = useMemo(() => daysInMonth(year, month), [month, year])

    const classes = useStyles()
    const isSamsung = isSamsungInternet()

    if (!minAge) {
        return null
    }

    const handleEntered = () => {
        disableBodyScroll()
    }

    const handleExiting = () => {
        enableBodyScroll()
        getFirstFocusableChild(document)
    }

    const handleClose = (e) => {
        e.preventDefault()

        const dayInt = parseInt(day)
        const monthInt = parseInt(month) -1  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/setMonth#monthvalue
        const yearInt = parseInt(year)

        if (yearInt === currentYear) {
            setIsFormDirty(true)
            return
        }
        const dateOfBirth = new Date(yearInt, monthInt, dayInt)
        const _isUnderaged = isUnderaged(dateOfBirth,  minAge)
        const expires = _isUnderaged ? { expires: 1 } : { expires: 365 }
        Cookie.set('dob', formatDate(yearInt, monthInt, dayInt), expires)

        setBlocked(_isUnderaged)
        setOpen(_isUnderaged)
    }

    const roleProps = blocked ? { role: 'alert' } : ''

    useEffect(() => {
        const _daysInMonth = daysInMonth(year, month)

        if (day > _daysInMonth) {
            setDay(1)
        }

        setIsFormDirty(false)
    }, [month, day, year])

    return (
        <Dialog
            data-testid={TEST_IDS.ageGate.container}
            fullScreen
            disableBackdropClick
            disableEscapeKeyDown
            open={open}
            onClose={handleClose}
            onEntered={handleEntered}
            onExiting={handleExiting}
            classes={{ root: classes.container }}
            PaperProps={{ className: classnames('bg-juvie', classes.paper) }}
            DialogContentProps={{ className: classes.content }}
            disableCloseButton
            aria-labelledby='age-gate-title'
        >
            <img className={classes.logo} src={gearsOfWarLogoWhite}  alt={t('Gears of War Logo')} />

            <Typography
                data-testid={TEST_IDS.ageGate.title}
                id='age-gate-title'
                variant='h1'
                {...roleProps}
                aria-live='assertive'
                className={classnames(classes.contentText, { [classes.contentTextBlocked]: blocked }, blocked ? 'h2' : 'h3')}
            >
                {blocked ? t('Access restricted') : t('Enter your date of birth')}
            </Typography>

            {!blocked && (
                <ThemeProvider theme={theme}>
                    {parseInt(year) === currentYear && isFormDirty && (
                        <div aria-live='assertive' aria-relevant='additions removals'>
                            <Typography className={classes.contentText} data-testid={TEST_IDS.ageGate.blockedMessage}>
                                {t('The fields have default values. Please enter your Date of Birth.')}
                            </Typography>
                        </div>
                    )}
                    <form method='dialog' className={classes.form} autoComplete='off' onSubmit={handleClose}>
                        <div className={classes.fieldContainer}>
                            <NativeSelect
                                data-testid={TEST_IDS.ageGate.year}
                                required
                                error={parseInt(year) === currentYear && isFormDirty}
                                classes={{ root: classes.select, icon: classes.icon }}
                                inputProps={{ 'aria-label': t('Year') }}
                                autoFocus={!isSamsung}
                                value={year}
                                onChange={(e) => setYear(e.target.value)}
                            >
                                {range(currentYear - 1899).map((i) => (
                                    <option key={`year-${i}`} value={currentYear - i}>
                                        {currentYear - i}
                                    </option>
                                ))}
                            </NativeSelect>
                            <NativeSelect
                                data-testid={TEST_IDS.ageGate.month}
                                required
                                classes={{ root: classes.select, icon: classes.icon }}
                                inputProps={{ 'aria-label': t('Month') }}
                                value={month}
                                onChange={(e) => setMonth(e.target.value)}
                            >
                                {range(12).map((i) => {
                                    const monthName = moment('1975-01-01', 'YYYY-MM-DD').add(i, 'months').format('MMMM')
                                    return (
                                        <option key={monthName} value={i + 1}>
                                            {t(monthName)}
                                        </option>
                                    )
                                })}
                            </NativeSelect>
                            <NativeSelect
                                data-testid={TEST_IDS.ageGate.day}
                                required
                                classes={{ root: classes.select, icon: classes.icon }}
                                inputProps={{ 'aria-label': t('Day') }}
                                value={day}
                                onChange={(e) => setDay(e.target.value)}
                            >
                                {range(numOfDaysInMonth).map((i) => (
                                    <option key={`day-${i}`} value={i + 1}>
                                        {i + 1}
                                    </option>
                                ))}
                            </NativeSelect>
                        </div>
                        <Button
                            data-testid={TEST_IDS.ageGate.submit}
                            type='submit'
                            variant='contained'
                            color='primary'
                            classes={{ root: classes.submit }}
                        >
                            {t('Submit')}
                        </Button>
                    </form>
                </ThemeProvider>
            )}
        </Dialog>
    )
}

function getAge(inputDate) {
    var today = new Date()
    var birthDate = new Date(inputDate)
    var age = today.getFullYear() - birthDate.getFullYear()
    var m = today.getMonth() - birthDate.getMonth()

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
    }
    return age
}


export default AgeGate
