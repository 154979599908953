import React, { useState, forwardRef } from 'react'
import TCMobileMenuItem from 'tcweb-material-components/core/siteMenu/mobileMenuItem'

import Link from '../../common/link'

const MobileMenuItem = forwardRef(({ children, renderMenuItemImage, ...rest }, ref) => {
    const [isActive, setIsActive] = useState(false)

    const getProps = ({ isPartiallyCurrent }) => {
        setIsActive(isPartiallyCurrent)
        return null
    }

    return (
        <TCMobileMenuItem
            {...rest}
            isActive={rest.item.url ? isActive : true}
            getProps={getProps}
            component={Link}
            ref={ref}
            renderMenuItemImage={renderMenuItemImage}
        />
    )
})

export default MobileMenuItem
