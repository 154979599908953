import { useTheme } from '@material-ui/core/styles'

import placeholderImgWhite from '../assets/images/placeholder-white.jpg'
import placeholderImgBlack from '../assets/images/placeholder-black.jpg'
import gearsOfWar from '../assets/images/news/gearsOfWar.jpg'
import theCoalition from '../assets/images/news/theCoalition.jpg'

const IMAGES = {
    placeholderImg: { white: placeholderImgWhite, black: placeholderImgBlack },
    gearsOfWar: { white: gearsOfWar, black: gearsOfWar },
    theCoalition: { white: theCoalition, black: theCoalition }
}

const useThemedImage = (name = 'placeholderImg') => {
    const theme = useTheme()

    if (theme.palette.type === 'light') {
        return IMAGES[name].white
    } else {
        return IMAGES[name].black
    }
}

export default useThemedImage
