import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import classNames from 'classnames'
import Button from 'tcweb-material-components/core/button'
import Dialog from 'tcweb-material-components/core/dialog'
import { mergeClasses } from 'tcweb-material-components/core/utilities/styles'

import GenericError from './error'
import { stripProtocol } from '../../utilities/helpers'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
    button: { width: '100%' },
    skeletonRoot: { backgroundColor: 'rgba(255, 255, 255, .2)' },
    displayNone: { display: 'none' },
    iframeSizing: {
        display: 'block',
        width: `calc(100vw - 2 * ${theme.gutters.modal.xs})`,
        height: `calc((100vw - 2 * ${theme.gutters.modal.xs}) * 7.6/16)`,
        maxWidth: '100%',
        maxHeight: '100%',
        [theme.breakpoints.up('md')]: {
            width: `calc(100vw - 2 * ${theme.gutters.modal.md})`,
            height: `calc((100vw - 2 * ${theme.gutters.modal.md}) * 7.6/16)`
        }
    }
}))

const TwitchDialog = ({ title, href, children, twitchVideoUrlRegex, onClick, classes, ...rest }) => {
    const _classes = useStyles()
    const { t } = useTranslation()
    const [open, setOpen] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(true)

    const matches = twitchVideoUrlRegex.exec(href)
    const videoId = matches.groups.video
    const clipId = matches.groups.clip
    const hasError = !!videoId && !!clipId

    const handleClick = () => {
        setOpen(true)
        setIsLoading(true)
        if (onClick) {
            onClick()
        }
    }

    const handleClose = () => setOpen(false)

    return (
        <>
            <Button
                aria-label={t('slug:twitch-modal-label', { title })}
                {...rest}
                classes={mergeClasses({ root: _classes.button }, classes)}
                role='button'
                onClick={handleClick}
            >
                {children}
            </Button>

            <Dialog
                fullScreen
                color='secondary'
                open={open}
                labelledBy='infoPopoverTitle'
                title={hasError ? t('Error loading video') : title || 'Watch Video'}
                onClose={handleClose}
                disableCloseButton={false}
                CloseButtonProps={{ title: t('slug:click-to-close-title-modal', { title: title }) }}
                classes={{
                    dialogTitleTypography: 'h2'
                }}
            >
                {hasError ? (
                    <GenericError />
                ) : (
                    <>
                        {isLoading && <Skeleton variant='rect' classes={{ root: classNames(_classes.skeletonRoot, _classes.iframeSizing) }} />}
                        {!!videoId && (
                            <iframe
                                title={t('slug:inline-twitch-video', { title: title })}
                                className={isLoading ? _classes.displayNone : _classes.iframeSizing}
                                onLoad={() => {
                                    setIsLoading(false)
                                }}
                                src={`https://player.twitch.tv/?video=${videoId}&parent=${stripProtocol(process.env.GATSBY_SITE_URL)}`}
                                height='720'
                                width='1280'
                                frameBorder='0'
                                scrolling='no'
                                allowFullScreen
                            ></iframe>
                        )}
						{!!clipId && (
                            <iframe
                                title={t('slug:inline-twitch-video', { title: title })}
                                className={isLoading ? _classes.displayNone : _classes.iframeSizing}
                                onLoad={() => {
                                    setIsLoading(false)
                                }}
                                src={`https://clips.twitch.tv/embed?clip=${clipId}`}
                                height='720'
                                width='1280'
                                frameBorder='0'
                                scrolling='no'
                                allowFullScreen
                            ></iframe>
                        )}
                    </>
                )}
            </Dialog>
        </>
    )
}

export default TwitchDialog
