import React, { useContext, forwardRef } from 'react'
import GatsbyLink from 'gatsby-link'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import Button from 'tcweb-material-components/core/button'
import classnames from 'classnames'

import TwitchDialog from './twitchDialog'
import LocaleContext from '../context/localeContext'
import { getSlugFromFullUrl, isInternalLink } from '../../utilities/helpers'

const twitchVideoUrlRegex = /^(https?:\/\/)?(www\.)?(twitch\.tv\/videos\/)(?<video>\w+)/
const twitchClipUrlRegex = /^(https?:\/\/)?(www\.)(twitch\.tv\/(\w+)\/clip\/)(?<clip>\w+)/

const useClasses = makeStyles(theme => ({
    linkRoot: {
        [theme.breakpoints.up('md')]: {
            maxWidth: 'calc(100vw / 3)'
        }
    }
}))

const Link = forwardRef(({ to, href, children, isImplicit, isFullUrl, getProps, target, className, ...rest }, ref) => {
    const url = href ? href : to || ''
    const localeContext = useContext(LocaleContext)
    const _classes = useClasses()

    delete rest.anchorClassName
    delete rest.showLabel

    // Dialog links
    if (twitchVideoUrlRegex.test(url)) {
        return (
            <TwitchDialog {...rest} className={className} href={url} twitchVideoUrlRegex={twitchVideoUrlRegex} buttonRef={ref}>
                {children}
            </TwitchDialog>
        )
    }

    if (twitchClipUrlRegex.test(url)) {
        return (
            <TwitchDialog {...rest} className={className} href={url} twitchVideoUrlRegex={twitchClipUrlRegex} buttonRef={ref}>
                {children}
            </TwitchDialog>
        )
    }

    // Relative internal links that need a locale
    if (target !== '_blank' && !isFullUrl && url && isInternalLink(url)) {
        const slug = getSlugFromFullUrl(url)
        const pathWithLocale =
            isImplicit || localeContext.isImplicit ? `/${slug.replace(/^\/+/, '')}` : `/${localeContext.locale}/${slug.replace(/^\/+/, '')}`

        return (
            <Button
                {...rest}
                className={classnames(_classes.linkRoot, className)}
                getProps={getProps}
                href={pathWithLocale}
                component={GatsbyLink}
                buttonRef={ref}
                target={target}
                role={!!pathWithLocale ? "link": "button"}
            >
                {children}
            </Button>
        )
    }
    // Relative internal links that dont need a locale
    else if((target !== '_blank' && !!isFullUrl && url && isInternalLink(url)) ){
        return (
            <Button
                {...rest}
                className={classnames(_classes.linkRoot, className)}
                getProps={getProps}
                href={url}
                component={GatsbyLink}
                buttonRef={ref}
                target={target}
                role={!!url ? "link": "button"}
            >
                {children}
            </Button>
        )
    }

    // All other links
    return (
        <Button {...rest} className={classnames(_classes.linkRoot, className)} buttonRef={ref} target={target || '_blank'} href={url} role={!!url ? "link": "button"}>
            {children}
        </Button>
    )
})

Link.propTypes = {
    children: PropTypes.node,
    to: PropTypes.string,
    href: PropTypes.string,
    isFullUrl: PropTypes.bool,
    isImplicit: PropTypes.bool
}

Link.defaultProps = {
    children: null,
    to: null,
    href: null,
    isFullUrl: false,
    isImplicit: false
}

export default Link
