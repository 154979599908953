import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Image from 'tcweb-material-components/core/image'

import useThemedImage from '../../hooks/useThemedImage'

const useClasses = makeStyles(() => ({
    root: { width: '100%' }
}))

export default ({ className, role, name, ...rest }) => {
    const { t } = useTranslation()
    const _classes = useClasses()
    const placeHolderImg = useThemedImage(name || 'placeholderImg')

    if (role === 'presentation') {
        return (
            <Image
                className={classNames(className, _classes.root)}
                role='presentation'
                src={placeHolderImg}
                alt=''
                {...rest}
            />
        )
    } else {
        return (
            <Image
                className={classNames(className, _classes.root)}
                src={placeHolderImg}
                alt={t('default placeholder asset with The Coalition Logo in the center')}
                {...rest}
            />
        )
    }
}
